body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PSLxText', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'PSLxText';
  src: local('PSLxText'),
    url('./assets/fonts/FontPsl/PSLxText-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'PSLxText';
  src: local('PSLxText-Bold'),
    url('./assets/fonts/FontPsl/PSLxText-Bold.ttf') format('truetype');
  font-weight: bold;
}

/* div {
  border: 1px solid red;
} */
